const ComingSoon = () => {

    return (
        <div className='page-inner'>
            <h1 className='coming-soon fade-in-heading m-0'>
                <span className='color-primary'>N</span><span className='color-primar'>EBULA</span>
                <span className='color-secondary'>V</span><span className='color-secondar'>OID</span>
            </h1>
            <p className='paragraph text-center fade-in-paragraph m-0'>coming soon</p>
        </div>
    );
}

export default ComingSoon;